import React from 'react';
import Main from './components/main';

export default function App() {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ margin: "auto", maxWidth: 800 }}>
        <Main/>
      </div>
    </div>
  );
}
