import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

import CopyIcon from '@material-ui/icons/FileCopy';
import PlusIcon from '@material-ui/icons/PlusOne';

import { buildSecretSantaTable } from 'secret-santa-generator';

import moment from "moment";

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            generated: {},
            open: false,
            count: 2,
            "name-0": "",
            "name-1": "",
        };
    }

    render() {
        const enabled = Object.keys(this.state)
            .filter((item) => item.startsWith("name-"))
            .reduce((reduced, item) => reduced && !!this.state[item], true)

        return <>
            <Card style={{ padding: 10 }}>
                <h1>
                    Коледа в устата
                </h1>
                <h2>
                    Година { moment().year() }
                </h2>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <b> Участници: </b>
                    { this.renderNameInputs() }
                </div>
                <br/>
                <Button onClick={ () => this.setState({ open: true, generated: this.generate() }) } disabled={ !enabled } color="primary" variant="outlined"> Изтегли </Button>
            </Card>
            <Dialog
                open={ this.state.open }
                onBackdropClick={ () => this.setState({ open: false }) }
            >
                { this.renderResults() }
            </Dialog>
            <Fab onClick={ this.addNameInput } color="primary" style={{ position: "fixed", bottom: 10, right: 10 }}>
                <PlusIcon/>
            </Fab>
        </>
    }

    copy = (id) => () => {
        /* Get the text field */
        var copyText = document.getElementById(id);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      
        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    renderResults = () => {
        return <div style={{ padding: 15 }}>
            <h1> Резултати </h1>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    Object.keys(this.state.generated)
                        .map((item, i) => {
                            const id = `result-item-${i}`;
                            return <div style={{ display: "flex", fontSize: "2em", borderBottom: "1px solid #EEE" }}>
                                <div style={{ flexGrow: 1 }}>
                                    <input style={{ width: 1, opacity: 0 }} id={id} value={`"${item}" подарява на "${this.state.generated[item]}"`}/>
                                    <span> <b> {item} </b> </span>
                                    <span> подарява на </span>
                                    <span> <b> { this.state.generated[item] } </b> </span>
                                </div>
                                <IconButton onClick={ this.copy(id) }> <CopyIcon/> </IconButton>
                            </div>;
                        })
                }
            </div>
        </div>
    }

    generate = () => {
        const names = Object.keys(this.state)
            .filter((item) => item.startsWith("name-"))
            .map((item) => this.state[item]);
        return buildSecretSantaTable(names);
    }

    renderNameInputs = () => {
        const inputs = [];
        for (let i = 0; i < this.state.count; i++) {
            inputs.push(<TextField value={ this.state[`name-${i}`] } onChange={ (e) => this.setState({[`name-${i}`]: e.target.value}) } placeholder="Име"/>)
        }
        return inputs;
    }

    addNameInput = () => {
        this.setState({
            count: this.state.count + 1,
            [`name-${this.state.count}`]: ""
        });
    }
}